<template>
  <v-container style="background: rgba(255, 255, 255, .1)" class="pa-0" fluid>
    <div>
      <Item>
        <ItemSection class="pr-3" side>
          <backArrow style="margin-left: 11px;margin-top: 13px;margin-bottom:15px;position: initial"/>
        </ItemSection>
      </Item>
      <p class="main-title mb-1 pb-2" style="margin-left: 25px;margin-top: -13px">{{ article.Question__c }}</p>
    </div>
    <div class='pa-6' style="overflow-y: auto; height: calc(100vh - (75px + 98px))"
    ><span class="main-text" v-html="article.Answer__c"/></div>
  </v-container>
</template>

<script>
import backArrow from '../../components/covid/back-arrow';
import { mapMutations } from 'vuex';
import { getArticleById } from '../../services/salesforce/KnowledgeService';
import Item from '../../components/structure/item/item'
import ItemSection from '../../components/structure/item/itemSection'

export default {
  name: 'knowledgeArticles',
  components: {
    backArrow,
    Item,
    ItemSection,
  },
  data() {
    return {
      articleId: null,
      article: {}
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    getArticle() {
      this.loading(true);

      getArticleById(this.articleId)
        .then((response) => {
          this.article = response.data.records[0];
        })
        .catch((error) => {
          this.showmsg({ text: "Erro ao carregar o artigo!", type: "error" });
        }).finally(() => {
        this.loading(false);
      });
    }
  },
  mounted() {
    const { articleId } = this.$route.query;

    this.articleId = articleId;

    this.getArticle();
  }
}
</script>

<style scoped>

</style>